/**
 * Copyright ©2024 Drivepoint
 */

import React, {ReactNode} from "react";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import DPDivider from "../DPDivider/DPDivider";
import "./DPDialog.css";

type DPDialogProps = {
  hideHeaderDivider?: boolean;
  title: string | ReactNode;
  actions?: (props: Pick<DialogProps, "onClose">) => ReactNode;
  children?: ReactNode;
  className?: string
} & Pick<DialogProps, "open" | "onClose">;

export default function DPDialog({title, children, actions, hideHeaderDivider, className, ...props}: DPDialogProps) {

  return <Dialog {...props} className={`dp-dialog-container ${className ?? ""}`}>
    <DialogTitle  className="dp-dialog-header">
      <div className="dp-dialog-header-title">{title}</div>
      <div className="dp-dialog-header-toolbar"></div>
    </DialogTitle>
    {!hideHeaderDivider && <DPDivider type="horizontal" />}
    <DialogContent>{children}</DialogContent>
    {actions &&  <DPDivider type="horizontal" />}
    {actions && <DialogActions className="dp-dialog-actions">{actions({onClose: props.onClose})}</DialogActions>}
  </Dialog>;
}
