/**
  * Copyright ©2024 Drivepoint
  */

import {EventBus, State, StateChangeEvent, Store} from "@bainbridge-growth/node-frontend";
import ModelSettings from "../services/microsoft/ModelSettings.ts";
import WebAppServerClient from "../services/WebAppServerClient.ts";
import NotFoundError from "../errors/NotFoundError.ts";

export default class DataFreshnessStore extends Store {

  private _timeout: any;

  init(): void {
    this.loading = true;
    State.registerMany("company", (event: StateChangeEvent) => {
      this.value = [];
      this._throttledRefresh();
    });
  }

  async refresh(): Promise<void> {
    clearTimeout(this._timeout);
    return this.action(async () => {
      const settings = await ModelSettings.get();
      if (!this.company) { throw new NotFoundError(); }
      try {
        const res = await WebAppServerClient.request(`/ui/company/${this.company.id}/dataStatus`, "GET");
        this.value = res?.data ?? [];

      }
      catch (error) {
        this.value = [];
        logger.error(error.message);
        EventBus.dispatch({
          type: "addin:notification:error",
          message: "Failed to load data sources latest upload info.",
          plan_id: settings.sharepointItemId,
          refresh: () => {this.refresh();}} as any);
      }
    });
  }

  private async _throttledRefresh(): Promise<void> {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.refresh();
    }, 1500);
  }

  get company(): any {
    return State.get("company");
  }

}
