/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";
import {DirectionsRun} from "@mui/icons-material";
import useBackgroundOperations from "../../hooks/useBackgroundOperations.tsx";
import "./BackgroundOperationsIndicator.css";

export default function BackgroundOperationsIndicator(): any {

  const backgroundOperations = useBackgroundOperations();

  function renderIndicator(): any {
    if (!backgroundOperations.length) { return; }
    return <DirectionsRun className="background-operations-indicator-icon" />;
  }

  return <div className="background-operations-indicator">
    {renderIndicator()}
  </div>;

}
