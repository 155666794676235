/**
 * Copyright ©2024 Drivepoint
 */

import {EventBus, State, StateChangeEvent, Store} from "@bainbridge-growth/node-frontend";
import ModelSettings from "../services/microsoft/ModelSettings.ts";
import WebAppServerClient from "../services/WebAppServerClient.ts";
import NotFoundError from "../errors/NotFoundError.ts";

export default class PlanStore extends Store {

  private _timeout: any;

  init(): void {
    this.loading = true;
    State.registerMany("company", (event: StateChangeEvent) => {
      this.value = {};
      this._throttledRefresh();
    });
  }

  async refresh(): Promise<void> {
    clearTimeout(this._timeout);
    return this.action(async () => {
      const settings = await ModelSettings.get();
      if (!this.company || !settings) { throw new NotFoundError(); }
      try {
        this.value = await WebAppServerClient.request(`/api/company/${this.company.id}/plan/${settings.sharepointItemId}`, "GET")
            ?? {id: "not_smartmodel"};
      }
      catch (error) {
        this.value = {id: "not_smartmodel"};
        logger.error(error.message);
      }
    });
  }

  async updateModelStartDate(ModelStartDate: string): Promise<void> {
    return this.action(async () => {
      const settings = await ModelSettings.get();
      if (!this.company || !settings) {
        throw new NotFoundError();
      }
      try {
        await WebAppServerClient.request(
          `/api/company/${this.company.id}/plan/${settings.sharepointItemId}/modelSettings`,
          "PUT", {ModelStartDate});
      }
      catch (error) {
        logger.error(error.message);
        EventBus.dispatch({type: "addin:notification:error", message: "Failed to update plan settings", plan_id: settings.sharepointItemId, refresh: () => {this.refresh();}} as any);
      }

    });
  }

  private async _throttledRefresh(): Promise<void> {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.refresh();
    }, 1500);
  }

  get company(): any {
    return State.get("company");
  }

}
