import {List, ListItem, ListItemText} from "@mui/material";
import React from "react";

type DPErrorListComponentProps = {
  items: Record<string, any>[]
};

export default function DPErrorList({items = []}: DPErrorListComponentProps) {
  return <List>
    {items.map((item, index) => <ListItem key={item?.id ?? index}>
      <ListItemText>{item?.value}</ListItemText>
    </ListItem>)}
  </List>;
}