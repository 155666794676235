/**
 * Copyright ©2024 Drivepoint
 */

import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {CircularProgress, Typography} from "@mui/material";
import "./DPPageLoadingVeil.css";

export type PageLoadingVeilProps = {
  visible?: boolean;
};

export type DPPageLoadingVeilInterface = {
  show: (title?: string) => void;
  hide: () => void
};

function LoadingVeil(props: PageLoadingVeilProps, ref: any): any {

  const [visible, setVisible] = useState(props.visible);
  const [content, setContent] = useState<Record<string, any>>({title: null});

  function show(title: string | null = null) {
    setVisible(true);
    if (title) {
      setContent({title});
    }
  }

  function hide() {
    setVisible(false);
  }

  useImperativeHandle(ref, (): DPPageLoadingVeilInterface => ({show, hide}));

  if (!visible) { return; }

  return <div className="page-loading-veil">
    <CircularProgress style={{marginBottom: "10px"}} />
    {content.title && <Typography variant="body1">{content.title}</Typography>}
  </div>;

}

const DPPageLoadingVeil = forwardRef<DPPageLoadingVeilInterface, PageLoadingVeilProps>(LoadingVeil);
export default DPPageLoadingVeil;
