/**
 * Copyright ©2024 Drivepoint
 */

export interface IDataPointCellObject {
  metricName?: string;
  cellAddress?: string;
  oldValue?: any,
  newValue?: any,
  newValueArray?: any,
  cellAddressRange?: any,
}

export default class Constants {

  static collectionNameExcelCompanies = "excelCompanies";
  static collectionNameExcelUsers = "excelUsers";
  static collectionNameExcelScenariosSums = "excelScenariosSums";
  static fieldNameExcelScenariosExecDashFiles = "excelScenariosExecDashFiles";
  static fileNameExecDashScenarioList = "execDashScenarioList";
  static fileNameExecDashScenarioListv5 = "eDScenarioListv5"; // name change here so the 'streaming' job ignores this file, but we stull write it, and we can tell if its a v5 or v4 save.
  static jsonFileSuffix = "json";
  static jsonLinesFileSuffix = "jsonl";
  static excelSuffix = "EXCEL";
  static collectionNameExcelPlans = "excelPlans";
  static annualSummarySuffix = "annualSummary";
  static annualSummarySuffixOld = "annual_summary";
  static sheetNameFinancialsNamedRangeSuffix = "financials";
  static sheetNameFinancialsNamedRangeSuffixv5 = "finv5"; // name change here so the 'streaming' job ignores this file, but we stull write it, and we can tell if its a v5 or v4 save.
  static sheetNameExecDataForecastNamedRangeSuffix = "exec_data";
  static sheetNameExecDataForecastNamedRangeSuffixv5 = "edv5"; // name change here so the 'streaming' job ignores this file, but we stull write it, and we can tell if its a v5 or v4 save.
  static sheetNameModelMonthlyModelOutputNamedRangeSuffix = "modelOutput";
  static sheetNameModelMonthlyModelOutputNamedRangeSuffixv5 = "mov5"; // name change here so the 'streaming' job ignores this file, but we stull write it, and we can tell if its a v5 or v4 save.
  static sheetNameDriversKeyyModelOutputNamedRangeSuffix = "keyDrivers"; // delete me after v5 goes live
  static timeSeriesAlgorithm = "timeSeries";
  static SheetNameRanges = "Ranges";
  static SheetNameRangesXAxisNamedRange = "'Ranges'!$A$1:$HA$1"; // 'Ranges.ColumnLabels';
  static SheetNameRangesYAxisNamedRange = "'Ranges'!$A$1:$A$500"; //"Ranges.RowLabels";
  static SheetNameRangesXAxisRowsToSkipFromHeaderToData = 1; // 4
  static SheetNameRangesYAxisColumnsToSkipFromHeaderToData = 1; // 4
  // static sheetNameDriversKey = "Key Drivers";
  static sheetNameModelMonthlyXAxisRowsToSkipFromHeaderToData = 2;
  static sheetNameModelMonthlyYAxisColumnsToSkipFromHeaderToData = 3;
  static sheetNameModelMonthly = "M - Monthly";

  /*
      v4 metrics as of 20210912
    */
  static metricsArray = [
    "incomeStatement.grossSales",
    "incomeStatement.grossSales.dtcOnline",
    "incomeStatement.grossSales.marketplace",
    "incomeStatement.grossSales.wholesale",
    "incomeStatement.grossSales.retail",
    "incomeStatement.discounts",
    "incomeStatement.discounts.dtcOnline",
    "incomeStatement.discounts.marketplace",
    "incomeStatement.discounts.wholesale",
    "incomeStatement.discounts.retail",
    "incomeStatement.returns",
    "incomeStatement.returns.dtcOnline",
    "incomeStatement.returns.marketplace",
    "incomeStatement.returns.wholesale",
    "incomeStatement.returns.retail",
    "incomeStatement.shippingIncome",
    "incomeStatement.shippingIncome.dtcOnline",
    "incomeStatement.shippingIncome.marketplace",
    "incomeStatement.shippingIncome.wholesale",
    "incomeStatement.shippingIncome.retail",
    "incomeStatement.taxesCollected",
    "incomeStatement.taxesCollected.dtcOnline",
    "incomeStatement.taxesCollected.marketplace",
    "incomeStatement.taxesCollected.wholesale",
    "incomeStatement.taxesCollected.retail",
    "incomeStatement.netRevenue",
    "incomeStatement.netRevenue.dtcOnline",
    "incomeStatement.netRevenue.marketplace",
    "incomeStatement.netRevenue.wholesale",
    "incomeStatement.netRevenue.retail",
    "incomeStatement.costOfGoodsSold",
    "incomeStatement.costOfGoodsSold.dtcOnline",
    "incomeStatement.costOfGoodsSold.marketplace",
    "incomeStatement.costOfGoodsSold.wholesale",
    "incomeStatement.costOfGoodsSold.retail",
    "incomeStatement.productCost",
    "incomeStatement.productCost.dtcOnline",
    "incomeStatement.productCost.marketplace",
    "incomeStatement.productCost.wholesale",
    "incomeStatement.productCost.retail",
    "incomeStatement.importFreight",
    "incomeStatement.importFreight.dtcOnline",
    "incomeStatement.importFreight.marketplace",
    "incomeStatement.importFreight.wholesale",
    "incomeStatement.importFreight.retail",
    "incomeStatement.importDutiesAndTaxes",
    "incomeStatement.importDutiesAndTaxes.dtcOnline",
    "incomeStatement.importDutiesAndTaxes.marketplace",
    "incomeStatement.importDutiesAndTaxes.wholesale",
    "incomeStatement.importDutiesAndTaxes.retail",
    "incomeStatement.grossProfit",
    "incomeStatement.grossProfit.dtcOnline",
    "incomeStatement.grossProfit.marketplace",
    "incomeStatement.grossProfit.wholesale",
    "incomeStatement.grossProfit.retail",
    "incomeStatement.fulfillmentCosts",
    "incomeStatement.fulfillmentCosts.dtcOnline",
    "incomeStatement.fulfillmentCosts.marketplace",
    "incomeStatement.fulfillmentCosts.wholesale",
    "incomeStatement.fulfillmentCosts.retail",
    "incomeStatement.otherVariableCosts",
    "incomeStatement.otherVariableCosts.dtcOnline",
    "incomeStatement.otherVariableCosts.marketplace",
    "incomeStatement.otherVariableCosts.wholesale",
    "incomeStatement.otherVariableCosts.retail",
    "incomeStatement.shippingExpense",
    "incomeStatement.shippingExpense.dtcOnline",
    "incomeStatement.shippingExpense.marketplace",
    "incomeStatement.shippingExpense.wholesale",
    "incomeStatement.shippingExpense.retail",
    "incomeStatement.merchantFees",
    "incomeStatement.merchantFees.dtcOnline",
    "incomeStatement.merchantFees.marketplace",
    "incomeStatement.merchantFees.wholesale",
    "incomeStatement.merchantFees.retail",
    "incomeStatement.variableExpenses",
    "incomeStatement.variableExpenses.dtcOnline",
    "incomeStatement.variableExpenses.marketplace",
    "incomeStatement.variableExpenses.wholesale",
    "incomeStatement.variableExpenses.retail",
    "incomeStatement.contributionProfit",
    "incomeStatement.contributionProfit.dtcOnline",
    "incomeStatement.contributionProfit.marketplace",
    "incomeStatement.contributionProfit.wholesale",
    "incomeStatement.contributionProfit.retail",
    "incomeStatement.directAdvertising",
    "incomeStatement.directAdvertising.dtcOnline",
    "incomeStatement.directAdvertising.marketplace",
    "incomeStatement.directAdvertising.wholesale",
    "incomeStatement.directAdvertising.retail",
    "incomeStatement.otherAdvertising",
    "incomeStatement.otherAdvertising.dtcOnline",
    "incomeStatement.otherAdvertising.marketplace",
    "incomeStatement.otherAdvertising.wholesale",
    "incomeStatement.otherAdvertising.retail",
    "incomeStatement.otherMarketing",
    "incomeStatement.otherMarketing.dtcOnline",
    "incomeStatement.otherMarketing.marketplace",
    "incomeStatement.otherMarketing.wholesale",
    "incomeStatement.otherMarketing.retail",
    "incomeStatement.marketingAgency",
    "incomeStatement.marketingAgency.dtcOnline",
    "incomeStatement.marketingAgency.marketplace",
    "incomeStatement.marketingAgency.wholesale",
    "incomeStatement.marketingAgency.retail",
    "incomeStatement.totalMarketingExpenses",
    "incomeStatement.totalMarketingExpenses.dtcOnline",
    "incomeStatement.totalMarketingExpenses.marketplace",
    "incomeStatement.totalMarketingExpenses.wholesale",
    "incomeStatement.totalMarketingExpenses.retail",
    "incomeStatement.payroll",
    "incomeStatement.payroll.currentStaffSalariesAndBenefits",
    "incomeStatement.payroll.bonusPayouts",
    "incomeStatement.payroll.newStaffSalariesAndBenefits",
    "incomeStatement.legalAndProfessional",
    "incomeStatement.contractors",
    "incomeStatement.contractors.dtcOnline",
    "incomeStatement.contractors.marketplace",
    "incomeStatement.contractors.wholesale",
    "incomeStatement.contractors.retail",
    "incomeStatement.peopleCosts",
    "incomeStatement.generalAndAdministrative",
    "incomeStatement.researchAndDevelopment",
    "incomeStatement.depreciation",
    "incomeStatement.amortization",
    "incomeStatement.otherOperatingExpenses",
    "incomeStatement.operatingExpenses",
    "incomeStatement.operatingIncome",
    "incomeStatement.otherExpenses",
    "incomeStatement.otherIncome",
    "incomeStatement.interestExpense",
    "incomeStatement.preTaxIncome",
    "incomeStatement.incomeTaxExpense",
    "incomeStatement.netIncome",
    "incomeStatement.EBITDA",
    "balanceSheet.currentAssets",
    "balanceSheet.cash",
    "balanceSheet.accountsReceivable",
    "balanceSheet.inventory.finishedGoodsInTransit",
    "balanceSheet.inventory.finishedGoodsInWarehouse",
    "balanceSheet.inventory",
    "balanceSheet.workInProgress",
    "balanceSheet.otherCurrentAssets",
    "balanceSheet.totalCurrentAssets",
    "balanceSheet.fixedAssets",
    "balanceSheet.accumulatedAmortization",
    "balanceSheet.prepaidCapitalFees",
    "balanceSheet.otherAssets",
    "balanceSheet.totalAssets",
    "balanceSheet.currentLiabilities",
    "balanceSheet.accountsPayable",
    "balanceSheet.otherCurrentLiabilities",
    "balanceSheet.lineOfCredit",
    "balanceSheet.totalCurrentLiabilities",
    "balanceSheet.longTermLiabilities",
    "balanceSheet.totalLiabilities",
    "balanceSheet.commonStock",
    "balanceSheet.beginningOfPeriodPaidInCapital",
    "balanceSheet.endOfPeriodPaidInCapital",
    "balanceSheet.retainedEarnings",
    "balanceSheet.totalEquity",
    "cashFlowStatement.netIncome",
    "cashFlowStatement.depreciation",
    "cashFlowStatement.amortization",
    "cashFlowStatement.changeInWorkingCapital",
    "cashFlowStatement.accountsReceivable",
    "cashFlowStatement.inventory",
    "cashFlowStatement.prepaidExpense",
    "cashFlowStatement.accountsPayable",
    "cashFlowStatement.otherCurrentAssets",
    "cashFlowStatement.otherCurrentLiabilities",
    "cashFlowStatement.netCashProvidedByOperatingActivities",
    "cashFlowStatement.capitalExpenditures",
    "cashFlowStatement.netCashUsedInInvestingActivities",
    "cashFlowStatement.equityInvestment",
    "cashFlowStatement.lineOfCredit",
    "cashFlowStatement.longTermDebt",
    "cashFlowStatement.netCashProvidedByFinancingActivities",
    "cashFlowStatement.netCashFlow",
    "cashFlowStatement.beginningOfPeriodCash",
    "cashFlowStatement.endOfPeriodCash",
    "metrics.grossSalesGrowthPercent",
    "metrics.netRevenueMarginPercent",
    "metrics.costOfGoodsSoldPercent",
    "metrics.grossMarginPercent",
    "metrics.contributionMarginPercent",
    "metrics.adSpendGrowthPercent",
    "metrics.contributionMarginAfterMarketingPercent",
    "metrics.totalOpexPercent",
    "metrics.EBITDAMarginPercent",
    "metrics.operatingMarginPercent",
    "metrics.taxRatePercent",
    "metrics.netIncomeMarginPercent",
    "metrics.daysSalesOutstanding",
    "metrics.daysPayableOutstanding",
    "metrics.orders",
    "metrics.orders.unallocated",
    "metrics.orders.marketplace",
    "metrics.orders.wholesale",
    "metrics.orders.retail",
    "metrics.fulfillmentCostPerOrder.dtcOnline",
    "metrics.shippingCostPerOrder.dtcOnline",
    "metrics.merchantFeesPercent.dtcOnline",
    "metrics.returningCustomerAverageOrderValue.dtcOnline",
    "metrics.orders.dtcOnline",
    "metrics.salesFromReturningCustomers.dtcOnline",
    "metrics.salesFromNewCustomers.dtcOnline",
    "metrics.blendedPaidCAC.dtcOnline",
    "metrics.newCustomersAcquired.dtcOnline",
    "metrics.averageOrderValue.dtcOnline",
    "metrics.returningCustomerAverageOrderValue.dtcOnline",
    "metrics.newCustomerAverageOrderValue.dtcOnline",
    "customMetric1",
    "customMetric2",
    "customMetric3",
    "customMetric4",
    "customMetric5",
    "customMetric6",
    "customMetric7",
    "customMetric8",
    "customMetric9",
    "customMetric10"
  ];

  static lettersToNumbers: any = {
    "A": 0,
    "B": 1,
    "C": 2,
    "D": 3,
    "E": 4,
    "F": 5,
    "G": 6,
    "H": 7,
    "I": 8,
    "J": 9,
    "K": 10,
    "L": 11,
    "M": 12,
    "N": 13,
    "O": 14,
    "P": 15,
    "Q": 16,
    "R": 17,
    "S": 18,
    "T": 19,
    "U": 20,
    "V": 21,
    "W": 22,
    "X": 23,
    "Y": 24,
    "Z": 25
  };

  static numbersToLetters: any = {
    "-1": "",
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F",
    6: "G",
    7: "H",
    8: "I",
    9: "J",
    10: "K",
    11: "L",
    12: "M",
    13: "N",
    14: "O",
    15: "P",
    16: "Q",
    17: "R",
    18: "S",
    19: "T",
    20: "U",
    21: "V",
    22: "W",
    23: "X",
    24: "Y",
    25: "Z"
  };
}
