/**
 * Copyright ©2024 Drivepoint
 */

import {Config, EventBus, State, StateChangeEvent} from "@bainbridge-growth/node-frontend";
import Firebase from "./Firebase.ts";
import ModelSettings from "./microsoft/ModelSettings.ts";

export default class ServerSentEventService extends EventTarget {

  private static _eventSource?: EventSource;
  private static _companyId?: string;
  private static _options: Record<string, any>;

  static async start(): Promise<void> {
    State.register("company", async (event: StateChangeEvent) => {
      const settings = await ModelSettings.get();
      ServerSentEventService._companyId = event?.value?.id;
      ServerSentEventService._options = {itemId: settings.sharepointItemId};
      await ServerSentEventService.reconnect();
    });
    State.register("token", async (event: StateChangeEvent) => {
      // only reconnect if token was updated (usually due to token expiration)
      if (event.value && event.previous) { await ServerSentEventService.reconnect(); }
    });
  }

  static async setOptions(options: Record<string, any>) {
    ServerSentEventService._options = {...this._options, ...options};
    await ServerSentEventService.reconnect();
  }

  static async connect(): Promise<void> {
    await ServerSentEventService.disconnect();
    try {
      if (!Firebase.token) { return; }
      if (ServerSentEventService._companyId) {
        ServerSentEventService._eventSource = new EventSource(`${Config.get("server.api.url")}/ui/event/${this._companyId}?token=${Firebase.token}&options=${JSON.stringify(this._options)}`);
      } else {
        ServerSentEventService._eventSource = new EventSource(`${Config.get("server.api.url")}/ui/event?token=${Firebase.token}`);
      }
      ServerSentEventService.addListeners();
    } catch (error: any) {
      logger.error(error.message);
    }
  }

  static onMessage(event: any): void {
    const data = JSON.parse(event.data);
    // slight hack: if the event is a state change, make sure State knows the new value, which will in turn trigger an EventBus.dispatch if needed
    const match = data.type?.match(/^state:(.+)$/);
    if (match) {
      State.set(match[1], data.value);
    } else {
      EventBus.dispatch(data);
    }
  }

  static onOpen(): void {
    State.set("sse", "connected");
  }

  static async onError(): Promise<void> {
    logger.trace("onError");
    State.set("sse", "disconnected");
  }

  static async disconnect(): Promise<void> {
    ServerSentEventService.reset();
    State.set("sse", "disconnected");
  }

  static async reconnect(delayMs?: number): Promise<void> {
    await ServerSentEventService.disconnect();
    if (delayMs !== undefined) {
      setTimeout(() => { ServerSentEventService.connect(); }, delayMs);
    } else {
      await ServerSentEventService.connect();
    }
  }

  static reset(): void {
    ServerSentEventService.removeListeners();
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.close();
      ServerSentEventService._eventSource = undefined;
    }
  }

  static addListeners(): void {
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.addEventListener("message", ServerSentEventService.onMessage);
      ServerSentEventService._eventSource.addEventListener("open", ServerSentEventService.onOpen);
      ServerSentEventService._eventSource.addEventListener("error", ServerSentEventService.onError);
    }
  }

  static removeListeners(): void {
    if (ServerSentEventService._eventSource) {
      ServerSentEventService._eventSource.removeEventListener("message", ServerSentEventService.onMessage);
      ServerSentEventService._eventSource.removeEventListener("open", ServerSentEventService.onOpen);
      ServerSentEventService._eventSource.removeEventListener("error", ServerSentEventService.onError);
    }
  }

}
