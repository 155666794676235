/**
 * Copyright ©2024 Drivepoint
 */

import React, {useState, forwardRef, useImperativeHandle} from "react";
import {Drawer, DrawerProps} from "@mui/material";

type DPDrawerProps = {
  defaultState?: boolean
} & Pick<DrawerProps, "anchor" | "style" | "children" | "PaperProps">;

export type DPDrawerInterface = {
  toggle: () => void
};

function MUIDrawer({children, ...props}: DPDrawerProps, ref: any) {

  useImperativeHandle(ref, (): DPDrawerInterface => ({toggle}));

  const [open, setOpen] = useState(props.defaultState ?? false);

  function toggle() {
    setOpen(prev => !prev);
  }

  return  <Drawer open={open} {...props} onClose={() => {setOpen(false);}}>
    {children}
  </Drawer>;
}

const DPDrawer = forwardRef<DPDrawerInterface, DPDrawerProps>(MUIDrawer);
export default DPDrawer
