/**
 * Copyright ©2024 Drivepoint
 */

export default class OfficeFile {

  static async getDocumentAsBytes(): Promise<number[]> {
    return new Promise((resolve, reject) => {
      try {
        Office.context.document.getFileAsync(Office.FileType.Compressed, async (result: Office.AsyncResult<Office.File>) => {
          if (result.status === Office.AsyncResultStatus.Failed) { throw new Error(result.error.message); }
          resolve(await OfficeFile.getFileContent(result.value));
        });
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async getDocumentUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        Office.context.document.getFilePropertiesAsync(null, (result: Office.AsyncResult<Office.FileProperties>) => {
          if (result.status === Office.AsyncResultStatus.Failed) { throw new Error(result.error.message); }
          resolve(result.value.url);
        });
      } catch (error: any) {
      }
    });
  }

  static async getSlice(file: Office.File, index: number): Promise<number[]> {
    return new Promise((resolve, reject) => {
      try {
        file.getSliceAsync(index, (slice: Office.AsyncResult<Office.Slice>) => {
          if (slice.status === Office.AsyncResultStatus.Failed) { return reject(new Error(slice.error.message)); }
          resolve(slice.value.data);
        });
      } catch (error: any) {
        reject(error);
      }
    });
  }

  static async getFileContent(file: Office.File): Promise<number[]> {
    try {
      const content: number[] = [];
      for (let index = 0; index < file.sliceCount; index++) {
        const slice = await OfficeFile.getSlice(file, index);
        for (const element of slice) { content.push(element); } // avoid RangeError with content.push(...slice) with large arrays
      }
      return content;
    } finally {
      file.closeAsync();
    }
  }

}
