/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";

export default function NotFoundPage(): any {

  return <div className="not-found-page">
    Not Found Page
  </div>;

}
