/**
 * Copyright ©2024 Drivepoint
 */

export default class ExcelFrameInfo {

  static documentRelativePath: string;

  static async start(): Promise<void> {
    const excelFrameInfo = JSON.parse(window.name || "{}");
    if (excelFrameInfo?.baseFrameName) {
      const baseDocumentRelativePath = excelFrameInfo.baseFrameName.match(/sites\/(.+?)\/(.+?)\/(.+).xlsx/)?.[3];
      if (baseDocumentRelativePath) {
        ExcelFrameInfo.documentRelativePath = `/${baseDocumentRelativePath}.xlsx`;
      }
    }
  }

}
