/**
 * Copyright ©2024 Drivepoint
 */

import {State} from "@bainbridge-growth/node-frontend";
import ITelemetryProvider from "./ITelemetryProvider.ts";
import DrivepointUser from "../DrivepointUser.ts";
import SegmentProvider from "./SegmentProvider.ts";
import FeatureFlagsService from "../FeatureFlagService.ts";

export default class Telemetry {

  private static _user: DrivepointUser;
  private static _company: any;

  static providers: ITelemetryProvider[] = [
    new SegmentProvider()
  ];

  static async start() {
    for (const provider of Telemetry.providers) {
      if (FeatureFlagsService.get(`excel.add.in.${provider.name}.enabled`)) { await provider.embed(); }
    }
    State.registerMany("user", "company", (event: any) => {
      switch (event.key) {
        case "user":
          Telemetry.user = event.value;
          break;
        case "company":
          Telemetry.company = event.value;
          break;
      }
      Telemetry.identify();
    });
  }

  static async identify() {
    for (const provider of Telemetry.providers) {
      if (FeatureFlagsService.get(`excel.add.in.${provider.name}.enabled`)) { provider.identify(); }
    }
  }

  static async track(event: string, data: any = {}): Promise<void> {
    for (const provider of Telemetry.providers) {
      if (FeatureFlagsService.get(`excel.add.in.${provider.name}.enabled`)) { provider.track(event, data); }
    }
  }

  static set user(value: DrivepointUser) {
    Telemetry._user = value;
  }

  static set company(value: any) {
    Telemetry._company = value;
  }

  static get user(): DrivepointUser {
    return Telemetry._user;
  }

  static get company(): any {
    return Telemetry._company;
  }

}
