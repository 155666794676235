/**
 * Copyright ©2024 Drivepoint
 */

import {v4 as uuidv4} from "uuid";
import {EventBus} from "@bainbridge-growth/node-frontend";

export type BackgroundOperation = {
  id: string;
  type: string;
  data?: any;
  start: number;
};

export default class BackgroundOperations {

  private static _operations: BackgroundOperation[] = [];

  static add(type: string, data?: any): string {
    const operation: BackgroundOperation = {
      id: uuidv4(),
      type,
      data: data ?? {},
      start: Date.now()
    };
    BackgroundOperations._operations.push(operation);
    EventBus.dispatch({type: "background_operations:add", operation} as any);
    BackgroundOperations.updateCloseNotification();
    return operation.id;
  }

  static update(id: string, data: any): void {
    const operation = BackgroundOperations._operations.find(it => it.id === id);
    if (!operation) { return; }
    operation.data = {...operation.data, ...data};
    EventBus.dispatch({type: "background_operations:update", operation, duration: Date.now() - operation.start} as any);
  }

  static remove(id: string): void {
    const operation = BackgroundOperations._operations.find(it => it.id === id);
    BackgroundOperations._operations = BackgroundOperations._operations.filter(it => it.id !== id);
    EventBus.dispatch({type: "background_operations:remove", operation, duration: Date.now() - operation.start} as any);
    BackgroundOperations.updateCloseNotification();
  }

  static updateCloseNotification(): void {
    if (BackgroundOperations.operations.length) {
      Office.addin.beforeDocumentCloseNotification.enable();
    } else {
      Office.addin.beforeDocumentCloseNotification.disable();
    }
  }

  static get operations(): BackgroundOperation[] {
    return BackgroundOperations._operations;
  }

}
