import {Source} from "./logic.ts";

import Shopify from "../../../assets/brands/shopify.svg";
import Amazon from "../../../assets/brands/amazon.svg";
import Netsuite from "../../../assets/brands/netsuite.svg";
import Quickbooks from "../../../assets/brands/quickbooks.svg";
import MuffinData from "../../../assets/brands/muffin_data.svg";

type DataSource = {
  id: Source, 
  name: string, 
  icon: string,
  status?: string,
  refreshedAt?: string
};

const dataSourcesMap: Record<Source, DataSource>  = {
  shopify: {id: "shopify", name: "Shopify", icon: Shopify},
  netsuite: {id: "netsuite", name: "Netsuite", icon: Netsuite},
  quickbooks: {id: "quickbooks", name: "Quickbooks", icon: Quickbooks},
  amazon: {id: "amazon", name: "Amazon Seller", icon: Amazon},
  muffindata: {id: "muffindata", name: "Muffin Data", icon: MuffinData}
};
export default dataSourcesMap;