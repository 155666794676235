/**
 * Copyright ©2024 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";
import Firebase from "./Firebase.ts";
import WebAppServerError from "../errors/WebAppServerError.ts";

export default class WebAppServerClient {

  static async getStandardOptions(method: string, body?: any): Promise<any> {
    let type = "application/json";
    if (body != null) {
      if (body instanceof Blob) {
        type = body.type;
      } else {
        body = typeof body === "string" ? body : JSON.stringify(body);
      }
    }
    return {
      headers: {
        "x-drivepoint-frontend": "drivepoint-excel-add-in",
        "Content-Type": type,
        "Authorization": Firebase.bearer
      },
      method: method,
      body: body
    };
  }

  static async request(path: string, method: string, body?: any, abortController?: AbortController): Promise<any> {
    const options = await this.getStandardOptions(method, body);
    options.signal = abortController?.signal;
    const response = await fetch(`${Config.get("server.api.url", window.location.origin)}${path}`, options);
    const data = response.headers.get("Content-Type").includes("application/json") ? response.json() : response.blob();
    if (response.status > 399) { throw new WebAppServerError(response.statusText, path, await data, response.status); }
    return data;
  }

}
