/**
 * Copyright ©2024 Drivepoint
 */

import React, {createContext, useEffect, useRef, useState, Suspense} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {EventBus, State, useStateChange} from "@bainbridge-growth/node-frontend";
import DPNotificationWrapper from "../DPNotificationWrapper/DPNotificationWrapper.tsx";
import Footer from "../Footer/Footer.tsx";
import Header, {HeaderInterface} from "../Header/Header.tsx";
import Page from "../Page/Page.tsx";
import Pages from "../../services/Pages.ts";
import SignInPage from "../../pages/SignInPage/SignInPage.tsx";
import NotFoundPage from "../../pages/NotFoundPage.tsx";
import Telemetry from "../../services/telemetry/Telemetry.ts";
import DrivepointUser from "../../services/DrivepointUser.ts";
import ModelSettings from "../../services/microsoft/ModelSettings.ts";
import ServerSentEventService from "../../services/ServerSentEventService.ts";
import "./App.css";

type MainContextProps = {
  updateHeaderTitle?: HeaderInterface["updateTitle"];
  updateHeaderAction?: HeaderInterface["updateAction"];
  disableMenuButton?: HeaderInterface["disableMenuButton"];
};

export const MainContext = createContext<MainContextProps>({});

export default function App() {

  const officeReady = useStateChange<boolean>("office:ready");
  const user = useStateChange<DrivepointUser>("user");
  const navigate = useNavigate();

  const [context, setContext] = useState<MainContextProps>(null);

  const header = useRef<HeaderInterface>();

  useEffect(() => {
    const registrations = EventBus.registerMany("show:page", "state:addin:visibility", "WorksheetChanged", "WorksheetNameChanged", async (event: any) => {
      if (event.type === "show:page") {
        const page = Pages.getPageByPath(event.id);
        if (page) { Telemetry.track("page_change", {page_id: page.id, name: page.name, path: page.path}); }
        navigate(event.id);
      }
      if (event.type === "state:addin:visibility") { Telemetry.track("addin_visibility", {visible: event.value}); }
      if (event.type === "WorksheetChanged") {
        if (event.worksheet?.name === "Settings") { onSettingsChanged(); }
      }
      if (event.type === "WorksheetNameChanged" && (event.nameAfter === "Settings" || event.nameBefore === "Settings")) {
        ModelSettings.clear();
        onSettingsChanged();
      }
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setContext({
        updateHeaderAction: header.current.updateAction,
        updateHeaderTitle: header.current.updateTitle,
        disableMenuButton: header.current.disableMenuButton
      });
    }
  }, [user]);

  async function onSettingsChanged(): Promise<void> {
    ModelSettings.clear();
    const settings = await ModelSettings.get();
    const company = State.get("company");
    if (!settings?.companyId || settings?.companyId !== company?.id) {
      const user = State.get("user");
      await ServerSentEventService.disconnect();
      State.set("company", user?.companies?.find(it => it.id === settings?.companyId));
      await ServerSentEventService.connect();
    }
  }

  function renderOfficeLoading(): any {
    return <div className="app-office-loading">
      <CircularProgress />
    </div>;
  }

  function renderPageRoute(page: any): any {
    const routes: any[] = [<Route key={page.id} path={page.path} element={<Suspense><page.element /></Suspense>} />];
    if (page.default) {
      routes.push(<Route key={`${page.id}_default`} path="/" element={<Suspense><page.element /></Suspense>} />);
      routes.push(<Route key={`${page.id}_index`} index element={<Suspense><page.element /></Suspense>} />);
    }
    return routes;
  }

  function renderPageRoutes(): any {
    return Pages.pages.map(renderPageRoute);
  }

  function renderDefaultPage(): any {
    const page = Pages.pages[0];
    if (page?.id) {
      return <>
        routes.push(<Route key={`${page.id}_default`} path="/" element={<Suspense><page.element /></Suspense>} />);
        routes.push(<Route key={`${page.id}_index`} index element={<Suspense><page.element /></Suspense>} />);
      </>;
    }
    return <Route path="*" element={<NotFoundPage />} />;
  }

  function renderRoutes(): any {
    if (!user) { return <SignInPage />; }
    return <Routes>
      {renderPageRoutes()}
      {renderDefaultPage()}
    </Routes>;
  }

  function render(): any {
    // if user is undefined, Firebase is still initializing:
    if (!officeReady || user === undefined) { return renderOfficeLoading(); }
    return <>
      <DPNotificationWrapper>
        {user && <Header ref={header} />}
        <MainContext.Provider value={context}>
          <Page>{renderRoutes()}</Page>
        </MainContext.Provider>
        <Footer />
      </DPNotificationWrapper>
    </>;
  }

  return <div className="app">
    {render()}
  </div>;

}
