/**
 * Copyright ©2024 Drivepoint
 */

import {buildSettingsFromRangeDataSettings, getInternalDataStructureForRangeData, getModelVersion, getRangeDataFromExcelTab, isVersionGreaterOrEqualTo, pivotDataForExecDash, pivotDataForTableData} from "./Utilities.ts";
import {FINANCIAL_IDS, M_MONTHLY_FINANCIAL_IDS_TO_FINANCIAL_IDS} from "./Variables";
import Constants from "./Constants.ts";
//import {versionCompatibilityMatrix} from "../components/App";
import WebAppServerClient from "../services/WebAppServerClient.ts";

export default class ExcelUtilities {

  /**
     * Get an Excel Worksheet by name.
     */
  static async getWorksheetByName(context: Excel.RequestContext, name: string): Promise<Excel.Worksheet> {
    const worksheets = context.workbook.worksheets;
    worksheets.load("items/name");
    await context.sync();
    return worksheets.items.find(worksheet => worksheet.name === name);
  }

  /**
   * Toggle ribbon button 'enabled' state
   * */

  static async toggleButtonState(buttonId: string, enabled: boolean = true) {
    await Office.ribbon.requestUpdate({
      tabs: [
        {id: "DrivepointTab",
          groups: [
            {id: `Group.${buttonId}`, controls: [{id: buttonId, enabled}]}
          ]
        }
      ]
    });
  }

  /**
     * Create an Excel Worksheet with a given name.
     */
  static async createWorksheet(context: Excel.RequestContext, name: string): Promise<Excel.Worksheet> {
    const worksheet = context.workbook.worksheets.add(name);
    await context.sync();
    return worksheet;
  }

  /**
     * Clear an Excel Worksheet.
     */
  static async clearWorksheet(worksheet: Excel.Worksheet): Promise<void> {
    worksheet.getRange().clear();
    await worksheet.context.sync();
  }

  /**
     * Create a worksheet, or clear an existing one.
     */
  static async createOrClearWorksheet(context: Excel.RequestContext, name: string): Promise<Excel.Worksheet> {
    let worksheet = await ExcelUtilities.getWorksheetByName(context, name);
    if (worksheet) {
      await ExcelUtilities.clearWorksheet(worksheet);
    } else {
      worksheet = await ExcelUtilities.createWorksheet(context, name);
    }
    return worksheet;
  }

  /**
     * Make a Range from two cells representing the upper-left and lower-right corners (as simple column/row numbers).
     */
  static makeRectRange(worksheet: Excel.Worksheet, x1, y1, x2, y2): Excel.Range {
    const cell = worksheet.getCell(y1, x1);
    return cell.getBoundingRect(worksheet.getCell(y2, x2));
  }

  /**
     * Perform a numberFormat change on the given range. The format string is an Excel format string, such as "mmm-yy"
     * or "#,##0_);(#,##0)"
     */
  static async numberFormatCellsRectRange(worksheet: Excel.Worksheet, range: Excel.Range, format: string): Promise<void> {
    range.numberFormat = [[format]];
    await worksheet.context.sync();
  }

  /**
     * Perform a style formatting change on the given range. The fillColor and fontColor are standard color strings,
     * such as "white" or "#70AD47"
     */
  static async formatCellsRectRange(worksheet: Excel.Worksheet, range: Excel.Range, fillColor: string, fontColor: string): Promise<void> {
    range.format.fill.color = fillColor;
    range.format.font.color = fontColor;
    await worksheet.context.sync();
  }

  static async batchSetWorksheetValues(range: Excel.Range, data: any[], batchSize: number, tab: any, updateProgress?: (data: any) => void, cellType?: "formulas" | "values"): Promise<void> {
    logger.debug(`batchSetWorksheetValues starting with batch size ${batchSize}`);
    const worksheet = range.worksheet;
    const batchCount = Math.ceil(data.length / batchSize);
    const startMs = Date.now();
    if (updateProgress) { updateProgress({context: "range", range: tab, action: "batch", batchSize: batchSize, batchCount: batchCount}); }
    for (let index = 0; index < batchCount; index++) {
      // the starting row to restore: 0, 250, 500, etc.
      const startRow = index * batchSize;
      // the ending row to restore: 249, 499, 749, etc. (adjusted if it is greater than the actual number of rows in data)
      const endRow = Math.min(startRow + batchSize, data.length) - 1;
      const startMs = Date.now();
      if (updateProgress) { updateProgress({context: "range", range: tab, action: "batch-start", index: index, start_row: startRow, end_row: endRow}); }
      logger.debug(`batch ${index}, rows ${startRow}-${endRow} starting`);
      // create a range that matches the shape of the data being restored, and then offset by the range's row/column
      // indices to match the destination in the worksheet
      const batchRange = worksheet
        .getCell(startRow, 0)
        .getBoundingRect(worksheet.getCell(endRow, range.columnCount - 1))
        .getOffsetRange(range.rowIndex, range.columnIndex);
      const section = data.slice(startRow, endRow + 1); // Array.slice(start, end) where "end" is not inclusive
      logger.debug(`batch ${index}, length: ${JSON.stringify(section).length}`);
      cellType === "formulas" ? batchRange.formulas = section : batchRange.values = section;
      await ExcelUtilities.sync(range.context);
      logger.debug(`batch ${index}, rows ${startRow}-${endRow} complete after ${Date.now() - startMs}ms`);
      if (updateProgress) { updateProgress({context: "range", range: tab, action: "batch-end", index: index, start_row: startRow, end_row: endRow, percent: (index + 1) / batchCount}); }
    }
    logger.debug(`batchSetWorksheetValues complete after ${Date.now() - startMs}ms`);
  }

  static async batchSetWorksheetValueWithRetry(range: Excel.Range, values: any[], tab: string, updateProgress?: (data: any) => void, cellType?: "formulas" | "values"): Promise<void> {
    const STARTING_PAYLOAD_BYTES = 1048576;
    const total_payload_size = JSON.stringify(values).length;
    let size = Math.ceil(range.rowCount / Math.ceil(total_payload_size / STARTING_PAYLOAD_BYTES));
    let message;
    do {
      try {
        return await ExcelUtilities.batchSetWorksheetValues(range, values, size, tab, updateProgress, cellType);
      } catch (error: any) {
        message = error.message;
        logger.debug(`failed trying to set worksheet values with batch size of ${size}: ${error.message}`);
        size = Math.ceil(size / 2);
      }
    } while (size > 5);
    throw new Error(`Could not set values for ${tab}: ${message}`);
  }

  /**
     * Gets all Named Ranges in the workbook, returned as a map of name => {scope, value}. Scope can be one of ["Worksheet", "Workbook"].
     */
  static async getNamedRanges(context: Excel.RequestContext): Promise<any> {
    const names = context.workbook.names;
    names.load("items");
    await context.sync();
    return names.items.reduce((items: any, item: Excel.NamedItem) => ({...items, [item.name]: {scope: item.scope, value: item.value}}), {});
  }

  /**
     * Given a Range, split it into multiple, smaller Ranges with a maximum number of rows and columns.
     */
  static async buildRangeBatches(range: Excel.Range, maxRowCount: number, maxColumnCount: number): Promise<Excel.Range[]> {
    const batches: Excel.Range[] = [];
    const rowCount = Math.ceil(range.rowCount / maxRowCount);
    const columnCount = Math.ceil(range.columnCount / maxColumnCount);
    for (let i = 0; i < rowCount; i++) {
      const startRow = i * maxRowCount;
      const endRow = Math.min(range.rowCount, ((i + 1) * maxRowCount)) - 1;
      for (let j = 0; j < columnCount; j++) {
        const startColumn = j * maxColumnCount;
        const endColumn = Math.min(range.columnCount, ((j + 1) * maxColumnCount)) - 1;
        const batchRange = ExcelUtilities.makeRectRange(
          range.worksheet,
          startColumn + range.columnIndex,
          startRow + range.rowIndex,
          endColumn + range.columnIndex,
          endRow + range.rowIndex
        );
        batches.push(batchRange);
      }
    }
    return batches;
  }

  static async sync(context: Excel.RequestContext): Promise<void> {
    await context.sync();
    context.application.suspendApiCalculationUntilNextSync();
    context.application.suspendScreenUpdatingUntilNextSync();
  }

  static async getCalculationMode(context?: Excel.RequestContext): Promise<Excel.CalculationMode | "Automatic" | "AutomaticExceptTables" | "Manual"> {
    if (context) {
      context.application.load("calculationMode");
      await ExcelUtilities.sync(context);
      return context.application.calculationMode;
    } else {
      return Excel.run(async (context: Excel.RequestContext) => {
        context.application.load("calculationMode");
        await ExcelUtilities.sync(context);
        return context.application.calculationMode;
      });
    }
  }

  static async setCalculationMode(mode: any, context?: Excel.RequestContext): Promise<void> {
    if (context) {
      context.application.calculationMode = mode;
      await ExcelUtilities.sync(context);
    } else {
      await Excel.run(async (context: Excel.RequestContext) => {
        context.application.calculationMode = mode;
        await ExcelUtilities.sync(context);
      });
    }
  }

  static async recalculate(): Promise<void> {
    await Excel.run(async (context: Excel.RequestContext) => {
      context.application.calculate(Excel.CalculationType.recalculate);
      await ExcelUtilities.sync(context);
    });
  }

  static async getPropertiesForRange(worksheet: Excel.Worksheet, range: Excel.Range, ...properties: string[]): Promise<any> {
    const results = properties.reduce((properties: any, property: string) => ({...properties, [property]: []}), {});
    range.load("columnCount, columnIndex, rowCount, rowIndex");
    await ExcelUtilities.sync(worksheet.context);
    const batches = await ExcelUtilities.buildRangeBatches(range, 64, 256);
    for (const batch of batches) {
      batch.load(`columnCount, columnIndex, rowCount, rowIndex, ${properties.join(", ")}`);
      await ExcelUtilities.sync(worksheet.context);
      for (let rowIndex = 0; rowIndex < batch.rowCount; rowIndex++) {
        for (let columnIndex = 0; columnIndex < batch.columnCount; columnIndex++) {
          for (const property of properties) {
            if (!results[property][rowIndex + batch.rowIndex]) { results[property][rowIndex + batch.rowIndex] = []; }
            results[property][rowIndex + batch.rowIndex][columnIndex + batch.columnIndex] = batch[property][rowIndex][columnIndex];
          }
        }
      }
    }
    for (const property of properties) {
      results[property] = results[property].filter(() => true);
      for (let row = 0; row < results[property].length; row++) { results[property][row] = results[property][row].slice(range.columnIndex); }
    }
    return results;
  }

  static async getDateSpine(worksheet: Excel.Worksheet, rowIndex: number = 1, firstColumnIndex: number = 10): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const usedRange = worksheet.getUsedRange();
        usedRange.load("columnIndex, columnCount");
        await ExcelUtilities.sync(worksheet.context);
        const range = worksheet.getRangeByIndexes(
          rowIndex,
          firstColumnIndex,
          1,
          usedRange.columnCount - firstColumnIndex + usedRange.columnIndex
        );
        range.load("values");
        await ExcelUtilities.sync(worksheet.context);
        const ids = range.values.flat();
        let lastIndex = ids.findIndex(it => !it);
        if (lastIndex === -1) { lastIndex = ids.length; }
        resolve(ids.slice(0, lastIndex));
      } catch (error: any) {
        logger.debug(error.message);
        reject(error);
      }
    });
  }

  /**
     * Get the Durable IDs for an appropriate range (typically yAxisNamedRange).
     */
  static async getDurableIDs(range: Excel.Range): Promise<any[]> {
    range.load("values");
    await range.context.sync();
    return range.values.reduce((ids: any[], id: any[], index: number) => {
      if (id[0]) { ids.push({index: index, id: id[0]}); }
      return ids;
    }, []);
  }

  static isTrue(value: any): boolean {
    if (typeof value === "boolean") { return value; }
    if (typeof value === "string") {
      switch (value.toLowerCase()) {
        case "true": return true;
        case "false": return false;
      }
    }
    return !!value;
  }

  static async withOptionalContext(closure: (context: Excel.RequestContext) => any, context?: Excel.RequestContext): Promise<any> {
    if (context) {
      return closure(context);
    }
    return Excel.run(async (context: Excel.RequestContext) => closure(context));
  }

  static makeBatches(array: any[], size: number): any[] {
    return array.reduce((batch, element, index) => {
      index % size === 0 ? batch.push([element]) : batch[Math.floor(index / size)].push(element);
      return batch;
    }, []);
  }

  static async isWorksheetProtected(context: Excel.RequestContext, worksheetName: string): Promise<boolean> {
    const worksheet = await ExcelUtilities.getWorksheetByName(context, worksheetName);
    worksheet.load("protection/protected");
    await worksheet.context.sync();
    return worksheet.protection.protected;
  }

  // trimmed down version of ScenarioSaveButtons.writeFinancialsDataFullJSONOutToCloud
  // This is not in the path of model save, is going to be in the path of Import Financials, so we omit operations that write to cloud starage, since theres no plan name
  // and we never have to do a reload from this data
  // primarily to ensure that smartModeldata.financial_data_raw is written during Import Financials, for Dazzler reporting purposes
  // https://www.notion.so/drivepoint/Import-Financials-should-save-financial_data_raw-in-the-DWH-09fe224f1998473f842b1311a4e32a71
  static async writeFinancialsDataFullJSONOutToCloudLight(context: any, sheetDestinationName: any, financialFilename:string, companyId:string, auth) {
    logger.debug("Going to write Financials to the DWH");
    let allRangeData = await getRangeDataFromExcelTab(context);
    const data = await getInternalDataStructureForRangeData(context, allRangeData["financials"]);
    const mMonthlyData = await getInternalDataStructureForRangeData(context, allRangeData["modelOutput"]);
    let settings = await getInternalDataStructureForRangeData(context, allRangeData["settings"]);
    settings = buildSettingsFromRangeDataSettings(settings, false, false);
    if (Object.keys(data).length === 0 || ExcelUtilities.isOneRow(data)) {
      throw new Error("Unable to read financial data. Please contact Drivepoint support team.");
    }
    let {filteredData, maxDayOfFinancials, filteredDataMMonthlyAllData} = ExcelUtilities.filter_monthly_financials_to_max_day(data, settings, mMonthlyData);
    let filteredDataMMonthlyReformattedLikeFinancials = ExcelUtilities.format_m_monthly_as_r_financials_for_saving_to_dwh(filteredDataMMonthlyAllData, maxDayOfFinancials);

    // in here we grab just the financials out of m-monthly and write them out too
    await ExcelUtilities.writeExecDashboardDataToDWHOnly(sheetDestinationName, filteredData, financialFilename, settings, false, companyId, auth);
    await ExcelUtilities.writeExecDashboardDataToDWHOnly(sheetDestinationName, filteredDataMMonthlyReformattedLikeFinancials, financialFilename, settings, true, companyId, auth);
  }

  static isOneRow(data: any): boolean {
    const rowsCount = Object.keys(data).map(key => Object.keys(data[key].values).length);
    return rowsCount.every(v => v === 1 || v === 0);
  }

  // trimmed down version of ScenarioSaveButtons.writeExecDashboardDataToCloud
  // this one removes the writing to the cloud as thats not needed here...
  // just take M-Monthly's financial data, transform it, and write it out
  static async writeExecDashboardDataToDWHOnly(sheetDestinationName, summationMetadataObject, metadataSuffix, settings, monthly_override, companyId, auth) {
    const sheetName = sheetDestinationName.trimLeft().trimRight();
    const modelVersion = await getModelVersion();
    let execDashData;
    if (metadataSuffix == Constants.sheetNameFinancialsNamedRangeSuffix || metadataSuffix == Constants.sheetNameFinancialsNamedRangeSuffixv5) {
      execDashData = pivotDataForTableData(summationMetadataObject, sheetName, companyId, settings);
    } else {
      execDashData = pivotDataForExecDash(summationMetadataObject, sheetName, companyId, settings);
    }
    try {
      let arrayForInsertionJson = JSON.stringify(execDashData);
      // for v5.. write to the API here
      if (metadataSuffix == Constants.sheetNameFinancialsNamedRangeSuffix || metadataSuffix == Constants.sheetNameFinancialsNamedRangeSuffixv5) {
        if (false) {
        //if (isVersionGreaterOrEqualTo(modelVersion, versionCompatibilityMatrix.v5)) {
          if (monthly_override) {
            logger.debug("writeToBigquery excel_upload_raw_financials_from_m_monthly ");
            //await WebAppServerClient.request(`/ui/company/${companyId}/excel_upload_raw_financials_from_m_monthly`, "PUT", auth, arrayForInsertionJson);
          } else {
            logger.debug("writeToBigquery excel_upload_raw_financials ");
            //await WebAppServerClient.request(`/ui/company/${companyId}/excel_upload_raw_financials`, "PUT", auth, arrayForInsertionJson);
          }
        }
      }
    } catch (e) {
      const error_message = "writeExecDashboardDataToDWHOnly:Unable to Correctly write metadata:: Error Code 20";
      logger.debug(error_message + e.toString());
      throw (e);
    }
  }

  static format_m_monthly_as_r_financials_for_saving_to_dwh(filteredDataMMonthlyAllData: {}, maxDayOfFinancials: number) {
    // reformat the m-monthly style data to the r-financial style prior to pivoting
    // metric.months.childArray.stringDate
    //
    // to
    // metric.values.exceldateInt
    //  cellAddress
    //  formulasheet
    //  metricFriendlyname
    //  metricName - use the new version  balancSheet.accountsPayable -> financial___accounts_payable
    //  valueSheet
    // also remove any values that Forecast Values and not Actuals
    let filteredDataMMonthlyReformattedLikeFinancials = {};
    for (let metricId in filteredDataMMonthlyAllData) {
      filteredDataMMonthlyReformattedLikeFinancials[metricId] = {};
      filteredDataMMonthlyReformattedLikeFinancials[metricId]["metricFriendlyName"] = filteredDataMMonthlyAllData[metricId]["metricFriendlyName"];
      filteredDataMMonthlyReformattedLikeFinancials[metricId]["firstCellAddress"] = filteredDataMMonthlyAllData[metricId]["firstCellAddress"];
      let valuesObject = filteredDataMMonthlyAllData[metricId]["months"]["childArray"];
      filteredDataMMonthlyReformattedLikeFinancials[metricId]["values"] = {};
      for (let stringDate in valuesObject) {
        let dateInt = valuesObject[stringDate]["dateInteger"];
        if (dateInt < maxDayOfFinancials) { // get rid of any data that is in the future.. we have to be 1 month BEFORE THE PRO-forma here!!!
          filteredDataMMonthlyReformattedLikeFinancials[metricId]["values"][dateInt] = {
            "cellAddress": valuesObject[stringDate]["cellAddress"],
            "formulaSheet": valuesObject[stringDate]["formulaSheet"],
            "metricFriendlyName": valuesObject[stringDate]["metricFriendlyName"],
            "metricName": M_MONTHLY_FINANCIAL_IDS_TO_FINANCIAL_IDS[valuesObject[stringDate]["metricName"]],
            "valueSheet": valuesObject[stringDate]["valueSheet"] ? valuesObject[stringDate]["valueSheet"] : 0.0
          };
        }
      }
    }
    return filteredDataMMonthlyReformattedLikeFinancials;
  }

  static filter_monthly_financials_to_max_day(data, settings, mMonthlyData) {
    const filteredData = Object.keys(data)
      .filter(key => FINANCIAL_IDS.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    // based on the data, when do we have data in R-Financials
    let maxDayOfFinancials = Object.keys(filteredData)
      .reduce((max, key) => {
        let max_keys = Math.max(Object.keys(filteredData[key]["values"]).map(val => parseInt(val)).filter(key => key > 0).reduce((a, b) => Math.max(a, b), -Infinity));
        max = max_keys > max ? max_keys : max;
        return max;
      }, 0);
    // lets take the model date if we have it from settings and make sure we have data, and only rolled-forward closed financials
    maxDayOfFinancials = Math.min(maxDayOfFinancials, settings?.modelStartDate);

    const reKeyedDataMonthly = Object.keys(mMonthlyData)
      .reduce((obj, key) => {
        obj[M_MONTHLY_FINANCIAL_IDS_TO_FINANCIAL_IDS[key]] = mMonthlyData[key];
        return obj;
      }, {});

    const filteredDataMMonthlyAllData = Object.keys(reKeyedDataMonthly)
      .filter(key => FINANCIAL_IDS.includes(key))
      .reduce((obj, key) => {
        obj[key] = reKeyedDataMonthly[key];
        return obj;
      }, {});
    return {filteredData, maxDayOfFinancials, filteredDataMMonthlyAllData};
  }

}
