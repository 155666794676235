/**
 * Copyright ©2024 Drivepoint
 */

import BackgroundOperations from "../BackgroundOperations.ts";
import OfficeFile from "./OfficeFile.ts";
import WebAppServerClient from "../WebAppServerClient.ts";

export default class ExcelService {

  static async saveModel(company: any, model: any): Promise<{error: any, response: any}> {
    let operation_id: string;
    const result: any = {error: undefined, response: undefined};
    try {
      operation_id = BackgroundOperations.add("Save Model", {stage: "get_document", model});
      model.data = await OfficeFile.getDocumentAsBytes();
      BackgroundOperations.update(operation_id, {stage: "create_model"});
      result.response = await WebAppServerClient.request(`/api/company/${company?.id}/model`, "POST", model);
    } catch (error: any) {
      result.error = error;
    } finally {
      BackgroundOperations.remove(operation_id);
    }
    return result;
  }

  static async copyModel(company: any, model: any): Promise<{error: any, response: any}> {
    let operation_id: string;
    const result: any = {error: undefined, response: undefined};
    try {
      operation_id = BackgroundOperations.add("Save Model", {stage: "get_document", model});
      BackgroundOperations.update(operation_id, {stage: "create_model"});
      result.response = await WebAppServerClient.request(`/api/company/${company?.id}/model/copy`, "POST", model);
    } catch (error: any) {
      result.error = error;
    } finally {
      BackgroundOperations.remove(operation_id);
    }
    return result;
  }

  static async getWorkbookProperties(...properties: string[]): Promise<any> {
    return new Promise((resolve, reject): void => {
      Excel.run(async (context: Excel.RequestContext): Promise<void> => {
        try {
          context.workbook.load(properties);
          await context.sync();
          resolve(properties.reduce((properties: any, property: string) => ({...properties, [property]: context.workbook[property]}), {}));
        } catch (error: any) {
          logger.error(error.message);
          reject(error);
        }
      });
    });
  }

  static async getCalculationMode(): Promise<Excel.CalculationMode | "Automatic" | "AutomaticExceptTables" | "Manual"> {
    return Excel.run(async (context: Excel.RequestContext) => {
      context.application.load("calculationMode");
      await context.sync();
      return context.application.calculationMode;
    });
  }

  static async setCalculationMode(mode: any): Promise<void> {
    await Excel.run(async (context: Excel.RequestContext) => {
      context.application.calculationMode = mode;
      await context.sync();
    });
  }

}
