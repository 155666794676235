/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";

import "./DPDivider.css";

type DPDividerProps = {
  type?: "vertical" | "horizontal"
};
export default function DPDivider({type = "horizontal"}: DPDividerProps) {
  return <div className={`dp-divider ${type === "horizontal" ? "dp-horizontal-divider" : "dp-vertical-divider"}`} />;
}
