/**
 * Copyright ©2024 Drivepoint
 */

import {State} from "@bainbridge-growth/node-frontend";
import LaunchDarklyClient from "./LaunchDarklyService.ts";

export default class FeatureFlagsService {

  private static _featureFlags: any = {};

  static async start(): Promise<void> {
    State.registerMany("user", "company", FeatureFlagsService.onUserStateChange);
    LaunchDarklyClient.client.on("change", FeatureFlagsService.refreshFeatureFlags);
    State.set("flags", FeatureFlagsService.featureFlags);
  }

  static async onUserStateChange(event: any): Promise<void> {
    await LaunchDarklyClient.identify(State.get("user"), State.get("company"));
    await FeatureFlagsService.refreshFeatureFlags();
  }

  static async refreshFeatureFlags(): Promise<void> {
    FeatureFlagsService.featureFlags = await LaunchDarklyClient.flags();
  }

  static get(key: string): any {
    return FeatureFlagsService._featureFlags?.[key];
  }

  static get featureFlags(): any {
    return FeatureFlagsService._featureFlags;
  }

  static set featureFlags(value: any) {
    FeatureFlagsService._featureFlags = value || {};
    State.set("flags", FeatureFlagsService._featureFlags);
  }

}
