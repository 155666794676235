/**
 * Copyright ©2024 Drivepoint
 */

import {useEffect, useState} from "react";
import {EventBus} from "@bainbridge-growth/node-frontend";
import BackgroundOperations, {BackgroundOperation} from "../services/BackgroundOperations.ts";

export default function useBackgroundOperations(): BackgroundOperation[] {

  const events: string[] = [
    "background_operations:add",
    "background_operations:update",
    "background_operations:remove"
  ];

  const [value, setValue] = useState<BackgroundOperation[]>(BackgroundOperations.operations);

  useEffect(() => {
    const registrations = EventBus.registerMany(...events, onEvent);
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  function onEvent(event: any): void {
    setValue([...BackgroundOperations.operations]);
  }

  return value;

}
