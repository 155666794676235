/**
 * Copyright ©2022 Drivepoint
 */

import {LicenseInfo} from "@mui/x-license-pro";

export default class Licenses {

  static init(): void {
    LicenseInfo.setLicenseKey(
      "edeeaf243b2537283222cb67e7b74285Tz05NTQxMixFPTE3NTQxNjUzMjcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
    );
  }

}