/**
 * Copyright ©2024 Drivepoint
 */

import React, {CSSProperties, ReactNode, useState} from "react";
import {InfoOutlined} from "@mui/icons-material";
import {Box, ClickAwayListener, Popper} from "@mui/material";
import {PopperProps} from "@mui/material/Popper";
import DPCard from "../DPCard/DPCard";

import "./DPTooltip.css";

type DPTooltipProps = {
  triggerEl?: ReactNode;
  children: ReactNode;
  cardCSS?: CSSProperties;
}& Pick<PopperProps, "placement">;

export default function DPTooltip({triggerEl, placement, children, cardCSS}: DPTooltipProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  return <>
    <span onClick={handleClick} style={{display: "inline-block", marginRight:"5px", cursor: "pointer"}} >
      {triggerEl ?? <InfoOutlined />}
    </span>
    <ClickAwayListener onClickAway={() => {setAnchorEl(null);}}>
      <Popper
        className="dp-popper"
        placement={placement}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {element: arrowRef}
          },
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: false,
              rootBoundary: "document",
              padding: 8
            }
          }
        ]}
        open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <DPCard className="dp-popper-body" style={cardCSS}>{children}</DPCard>
        <Box component="span" ref={setArrowRef} className={`arrow arrow-${placement}`} />
      </Popper>
    </ClickAwayListener>
  </>;
}
