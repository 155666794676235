/**
 * Copyright ©2024 Drivepoint
 */

import React, {ReactNode} from "react";
import {Card, CardProps, CircularProgress} from "@mui/material";
import DPDivider from "../DPDivider/DPDivider";
import "./DPCard.css";

type DPCardProps = {
  title?: string | ReactNode;
  toolbar?: ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
  size?: "standard" | "compact";
  showHeader?: boolean;
  showToolbar?: boolean;
  showHeaderDivider?: boolean
} & Pick<CardProps,
"draggable" | "style" | "className" | "elevation" | "onClick" | "onMouseEnter"
| "onMouseLeave" | "onDragStart" | "onDragOver" | "onDragEnd" | "onDragLeave" | "onDrop">;

export default function DPCard(
  {
    title,
    toolbar,
    children,
    isLoading = false,
    size = "standard",
    showToolbar = true,
    className,
    showHeader = true,
    showHeaderDivider = true,
    ...rest
  }: DPCardProps) {

  function renderTitle(): any {
    if (!title || !showHeader) { return; }
    const classes = ["dp-card-header"];
    if (size === "compact") { classes.push("compact"); }
    return <>
      <div className={`${classes.join(" ")}`}>
        <div className="dp-card-header-title">
          {title}
        </div>
        <div className="dp-card-header-toolbar">
          {toolbar}
        </div>
      </div>
      {showHeaderDivider && <DPDivider type="horizontal" />}
    </>;
  }

  return <Card className={`${className || ""} dp-card`} elevation={1} {...rest}>
    {renderTitle()}
    <div className={(!title || !showHeader) ? "dp-card-content-without-header" : "dp-card-content"}>
      {children}
    </div>
    {isLoading && <div className="dp-card-loader"><CircularProgress /></div>}
  </Card>;

}
