/**
 * Copyright ©2024 Drivepoint
 */

import {EventBus, State} from "@bainbridge-growth/node-frontend";

export default class ExcelEvents {

  static EXCEL_WORKBOOK_WORKSHEETS_EVENTS = ["onChanged", "onActivated", "onDeleted", "onAdded", "onNameChanged"];

  static _worksheet?: Excel.Worksheet;
  static _worksheets: Excel.Worksheet[] = [];

  static async start(): Promise<void> {
    await Office.onReady();
    await Excel.run(async (context: Excel.RequestContext) => {
      ExcelEvents.registerListeners(context);
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      worksheet.load("name, id");
      await context.sync();
      ExcelEvents.worksheet = worksheet;
    });
  }

  static registerListeners(context: Excel.RequestContext): void {
    for (const event of ExcelEvents.EXCEL_WORKBOOK_WORKSHEETS_EVENTS) {
      context.workbook.worksheets[event].add((event: string) => ExcelEvents.onWorksheetEvent(context, event));
    }
  }

  static async onWorksheetEvent(context: Excel.RequestContext, event: any): Promise<void> {
    if (event.type === "WorksheetAdded" || event.type === "WorksheetNameChanged" || event.type === "WorksheetDeleted") { ExcelEvents._worksheets = []; }
    Excel.run(async (context: Excel.RequestContext) => {
      const worksheet = await ExcelEvents.getWorksheetById(event.worksheetId, context);
      if (worksheet) {
        worksheet.load("name, id");
        await context.sync();
        event.worksheet = {name: worksheet.name};
      }
      switch (event.type) {
        case "WorksheetActivated":
          ExcelEvents.worksheet = worksheet;
          EventBus.dispatch(event);
          // TODO: track event with Segment
          break;
        default:
          EventBus.dispatch(event);
        // TODO: track event with Segment
      }
    });
  }

  static async getWorksheetById(id: string, context: Excel.RequestContext): Promise<Excel.Worksheet> {
    let worksheet = ExcelEvents._worksheets.find(worksheet => worksheet.id === id);
    if (!worksheet) {
      context.workbook.worksheets.load("items,name,id");
      await context.sync();
      ExcelEvents._worksheets = context.workbook.worksheets.items;
      worksheet = ExcelEvents._worksheets.find(worksheet => worksheet.id === id);
    }
    return worksheet;
  }

  static set worksheet(worksheet: Excel.Worksheet) {
    ExcelEvents._worksheet = worksheet;
    State.set("worksheet", {id: ExcelEvents._worksheet.id, name: ExcelEvents._worksheet.name});
  }

  static get worksheet(): Excel.Worksheet {
    return ExcelEvents._worksheet;
  }

}
