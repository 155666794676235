/**
 * Copyright ©2024 Drivepoint
 */

import React, {useEffect, useState} from "react";
import {Config, useStateChange} from "@bainbridge-growth/node-frontend";
import DrivepointLogo from "../../../assets/drivepoint.png";
import ModelSettings from "../../services/microsoft/ModelSettings.ts";
import "./Footer.css";

export default function Footer(): any {

  const company = useStateChange<any>("company");
  const sse = useStateChange<any>("sse");

  const [settings, setSettings] = useState<any>();

  useEffect(() => {
    ModelSettings.get().then(setSettings);
  }, []);

  function renderLogo(): any {
    const classes: string[] = ["footer-logo"];
    if (sse !== "connected") { classes.push("sse-disconnected"); }
    return <div className={classes.join(" ")}>
      <img src={DrivepointLogo} alt="Drivepoint" />
    </div>;
  }

  function renderEnvironment(): any {
    const environment = Config.get("environment", "");
    if (environment === "production") { return; }
    return <div className={`footer-environment footer-environment-${environment}`}>{environment.toUpperCase()}</div>
  }

  return <div className="footer">
    {renderLogo()}
    <div className="footer-product">Drivepoint <span className="footer-version">v{Config.get("version")}</span></div>
    {settings?.modelVersion && <div className="footer-product">Model <span className="footer-version">v{settings.modelVersion}</span></div>}
    {renderEnvironment()}
    <div className="footer-spacer" />
    <div className="footer-company-name">{company?.name ?? "Unknown Company"}</div>
  </div>;

}
