import {EventBus} from "@bainbridge-growth/node-frontend";
import {Icon, IconButton, Link, Typography} from "@mui/material";
import {closeSnackbar, enqueueSnackbar, SnackbarKey, SnackbarProvider} from "notistack";
import React, {ReactNode, useEffect} from "react";
import DPErrorList from "./components/DPErrorList.tsx";
import ModelSettings from "../../services/microsoft/ModelSettings.ts";
import DPButton from "../DPButton/DPButton.tsx";
import DPTooltip from "../DPTooltip/DPTooltip.tsx";

type DPNotificationProps = {
  children: ReactNode[]
};

export default function DPNotificationWrapper(props: DPNotificationProps) {

  useEffect(() => {
    const  registrations = EventBus.registerMany("notification:error", "notification:success", "addin:notification:error", "addin:notification:success",
      async (event: any) => {
        const settings = await ModelSettings.get();
        const isForCurrentPlan =  event.plan_id === settings.sharepointItemId;

        if (["notification:success", "addin:notification:success"].includes(event.type) && isForCurrentPlan) {enqueueSnackbar(event.message, {autoHideDuration: 6000});}
        if (["notification:error", "addin:notification:error"].includes(event.type) && isForCurrentPlan) {
          enqueueSnackbar(renderCustomErrorMessage(event?.error) ?? event.message, {
            persist: true,
            action: (snackbarId) =>
              <>
                {renderInfoAction(event?.content)}
                {renderCloseAction(snackbarId)}
              </>
          });
        }
      });

    return () => {
      EventBus.unregister(...registrations);
    };

  }, []);

  function renderCustomErrorMessage(code: number) {
    switch (code) {
      case 409:
        return "This plan name already exists. Please choose a new one.";
      default:
        return;
    }
  }
  
  function renderCloseAction(snackBarId: SnackbarKey) {
    return  <IconButton
      color="primary"
      onClick={() => {closeSnackbar(snackBarId);}}>
      <Icon>close</Icon>
    </IconButton>;
  }

  function renderInfoAction(content: Record<string, any>) {
    return   <DPTooltip triggerEl={<DPButton label="Info" variant="text" />}>
      <Typography variant="h6">{content?.title ?? "Failed To Update"}</Typography>
      {content?.message && <Typography variant="body2">{content?.message}</Typography>}
      {content?.type === "list" && <DPErrorList items={content?.items}/>}
      {!content && 
        <>
          <Typography variant="body2">
            Something went wrong. Please try again.
            If the issue persists please contact 
            <Link sx={{marginLeft: "5px"}} 
              target="_blank" 
              href="https://docs.drivepoint.io/help/v/help-1"
              underline="none">support</Link>.
          </Typography>
        </>
      }
    </DPTooltip>;
  }
    
  return <SnackbarProvider maxSnack={5} preventDuplicate>
        {...props.children}
  </SnackbarProvider>;
}