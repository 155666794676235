/**
 * Copyright ©2024 Drivepoint
 */

const KEY_PLAN_OPTIONS = [
  {key: "budgetPlanId", text: "Budget"},
  {key: "what_if", text: "What-if (Default)"}
];

// map of ids of financial terms from m-monthly to r-financial
const M_MONTHLY_FINANCIAL_IDS_TO_FINANCIAL_IDS = {
  "incomeStatement.grossSales" :	"",
  "incomeStatement.grossSales.dtcOnline" :	"financial___gross_sales__dtconline",
  "incomeStatement.grossSales.marketplace" :	"financial___gross_sales__marketplace",
  "incomeStatement.grossSales.wholesale" :	"financial___gross_sales__wholesale",
  "incomeStatement.grossSales.retail" :	"financial___gross_sales__retail",
  "incomeStatement.discounts" :	"",
  "incomeStatement.discounts.dtcOnline" :	"financial___discounts__dtconline",
  "incomeStatement.discounts.marketplace" :	"financial___discounts__marketplace",
  "incomeStatement.discounts.wholesale" :	"financial___discounts__wholesale",
  "incomeStatement.discounts.retail" :	"financial___discounts__retail",
  "incomeStatement.returns" :	"",
  "incomeStatement.returns.dtcOnline" :	"financial___returns__dtconline",
  "incomeStatement.returns.marketplace" :	"financial___returns__marketplace",
  "incomeStatement.returns.wholesale" :	"financial___returns__wholesale",
  "incomeStatement.returns.retail" :	"financial___returns__retail",
  "incomeStatement.shippingIncome" :	"",
  "incomeStatement.shippingIncome.dtcOnline" :	"financial___shipping_income__dtconline",
  "incomeStatement.shippingIncome.marketplace" :	"financial___shipping_income__marketplace",
  "incomeStatement.shippingIncome.wholesale" :	"financial___shipping_income__wholesale",
  "incomeStatement.shippingIncome.retail" :	"financial___shipping_income__retail",
  "incomeStatement.taxesCollected" :	"",
  "incomeStatement.taxesCollected.dtcOnline" :	"financial___taxes_collected__dtconline",
  "incomeStatement.taxesCollected.marketplace" :	"financial___taxes_collected__marketplace",
  "incomeStatement.taxesCollected.wholesale" :	"financial___taxes_collected__wholesale",
  "incomeStatement.taxesCollected.retail" :	"financial___taxes_collected__retail",
  "incomeStatement.netRevenue" :	"",
  "incomeStatement.netRevenue.dtcOnline" :	"",
  "incomeStatement.netRevenue.marketplace" :	"",
  "incomeStatement.netRevenue.wholesale" :	"",
  "incomeStatement.netRevenue.retail" :	"",
  "incomeStatement.costOfGoodsSold" :	"",
  "incomeStatement.costOfGoodsSold.dtcOnline" :	"",
  "incomeStatement.costOfGoodsSold.marketplace" :	"",
  "incomeStatement.costOfGoodsSold.wholesale" :	"",
  "incomeStatement.costOfGoodsSold.retail" :	"",
  "incomeStatement.productCost" :	"",
  "incomeStatement.productCost.dtcOnline" :	"financial___product_cost__dtconline",
  "incomeStatement.productCost.marketplace" :	"financial___product_cost__marketplace",
  "incomeStatement.productCost.wholesale" :	"financial___product_cost__wholesale",
  "incomeStatement.productCost.retail" :	"financial___product_cost__retail",
  "incomeStatement.importFreight" :	"",
  "incomeStatement.importFreight.dtcOnline" :	"financial___import_freight_cost__dtconline",
  "incomeStatement.importFreight.marketplace" :	"financial___import_freight_cost__marketplace",
  "incomeStatement.importFreight.wholesale" :	"financial___import_freight_cost__wholesale",
  "incomeStatement.importFreight.retail" :	"financial___import_freight_cost__retail",
  "incomeStatement.importDutiesAndTaxes" :	"",
  "incomeStatement.importDutiesAndTaxes.dtcOnline" :	"financial___import_duties_and_taxes__dtconline",
  "incomeStatement.importDutiesAndTaxes.marketplace" :	"financial___import_duties_and_taxes__marketplace",
  "incomeStatement.importDutiesAndTaxes.wholesale" :	"financial___import_duties_and_taxes__wholesale",
  "incomeStatement.importDutiesAndTaxes.retail" :	"financial___import_duties_and_taxes__retail",
  "incomeStatement.grossProfit" :	"",
  "incomeStatement.grossProfit.dtcOnline" :	"",
  "incomeStatement.grossProfit.marketplace" :	"",
  "incomeStatement.grossProfit.wholesale" :	"",
  "incomeStatement.grossProfit.retail" :	"",
  "incomeStatement.fulfillmentCosts" :	"",
  "incomeStatement.fulfillmentCosts.dtcOnline" :	"financial___fulfillment_costs__dtconline",
  "incomeStatement.fulfillmentCosts.marketplace" :	"financial___fulfillment_costs__marketplace",
  "incomeStatement.fulfillmentCosts.wholesale" :	"financial___fulfillment_costs__wholesale",
  "incomeStatement.fulfillmentCosts.retail" :	"financial___fulfillment_costs__retail",
  "incomeStatement.otherVariableCosts" :	"",
  "incomeStatement.otherVariableCosts.dtcOnline" :	"financial___other_variable_costs__dtconline",
  "incomeStatement.otherVariableCosts.marketplace" :	"financial___other_variable_costs__marketplace",
  "incomeStatement.otherVariableCosts.wholesale" :	"financial___other_variable_costs__wholesale",
  "incomeStatement.otherVariableCosts.retail" :	"financial___other_variable_costs__retail",
  "incomeStatement.shippingExpense" :	"",
  "incomeStatement.shippingExpense.dtcOnline" :	"financial___shipping_expense__dtconline",
  "incomeStatement.shippingExpense.marketplace" :	"financial___shipping_expense__marketplace",
  "incomeStatement.shippingExpense.wholesale" :	"financial___shipping_expense__wholesale",
  "incomeStatement.shippingExpense.retail" :	"financial___shipping_expense__retail",
  "incomeStatement.merchantFees" :	"",
  "incomeStatement.merchantFees.dtcOnline" :	"financial___merchant_fees__dtconline",
  "incomeStatement.merchantFees.marketplace" :	"financial___merchant_fees__marketplace",
  "incomeStatement.merchantFees.wholesale" :	"financial___merchant_fees__wholesale",
  "incomeStatement.merchantFees.retail" :	"financial___merchant_fees__retail",
  "incomeStatement.variableExpenses" :	"",
  "incomeStatement.variableExpenses.dtcOnline" :	"",
  "incomeStatement.variableExpenses.marketplace" :	"",
  "incomeStatement.variableExpenses.wholesale" :	"",
  "incomeStatement.variableExpenses.retail" :	"",
  "incomeStatement.contributionProfit" :	"",
  "incomeStatement.contributionProfit.dtcOnline" :	"",
  "incomeStatement.contributionProfit.marketplace" :	"",
  "incomeStatement.contributionProfit.wholesale" :	"",
  "incomeStatement.contributionProfit.retail" :	"",
  "incomeStatement.directAdvertising" :	"",
  "incomeStatement.directAdvertising.dtcOnline" :	"financial___direct_advertising__dtconline",
  "incomeStatement.directAdvertising.marketplace" :	"financial___direct_advertising__marketplace",
  "incomeStatement.directAdvertising.wholesale" :	"financial___direct_advertising__wholesale",
  "incomeStatement.directAdvertising.retail" :	"financial___direct_advertising__retail",
  "incomeStatement.otherAdvertising" :	"",
  "incomeStatement.otherAdvertising.dtcOnline" :	"financial___other_advertising__dtconline",
  "incomeStatement.otherAdvertising.marketplace" :	"financial___other_advertising__marketplace",
  "incomeStatement.otherAdvertising.wholesale" :	"financial___other_advertising__wholesale",
  "incomeStatement.otherAdvertising.retail" :	"financial___other_advertising__retail",
  "incomeStatement.otherMarketing" :	"",
  "incomeStatement.otherMarketing.dtcOnline" :	"financial___other_marketing__dtconline",
  "incomeStatement.otherMarketing.marketplace" :	"financial___other_marketing__marketplace",
  "incomeStatement.otherMarketing.wholesale" :	"financial___other_marketing__wholesale",
  "incomeStatement.otherMarketing.retail" :	"financial___other_marketing__retail",
  "incomeStatement.marketingAgency" :	"",
  "incomeStatement.marketingAgency.dtcOnline" :	"financial___marketing_agency__dtconline",
  "incomeStatement.marketingAgency.marketplace" :	"financial___marketing_agency__marketplace",
  "incomeStatement.marketingAgency.wholesale" :	"financial___marketing_agency__wholesale",
  "incomeStatement.marketingAgency.retail" :	"financial___marketing_agency__retail",
  "incomeStatement.totalMarketingExpenses" :	"",
  "incomeStatement.totalMarketingExpenses.dtcOnline" :	"",
  "incomeStatement.totalMarketingExpenses.marketplace" :	"",
  "incomeStatement.totalMarketingExpenses.wholesale" :	"",
  "incomeStatement.totalMarketingExpenses.retail" :	"",
  "incomeStatement.payroll" :	"financial___payroll",
  "incomeStatement.payroll_dtcOnline" :	"",
  "incomeStatement.payroll_marketplace" :	"",
  "incomeStatement.payroll_wholesale" :	"",
  "incomeStatement.payroll_retail" :	"",
  "incomeStatement.legalAndProfessional" :	"financial___legal_and_professional",
  "incomeStatement.legalAndProfessional_dtcOnline" :	"",
  "incomeStatement.legalAndProfessional_marketplace" :	"",
  "incomeStatement.legalAndProfessional_wholesale" :	"",
  "incomeStatement.legalAndProfessional_retail" :	"",
  "incomeStatement.contractors" :	"",
  "incomeStatement.contractors.dtcOnline" :	"financial___contractors__dtconline",
  "incomeStatement.contractors.marketplace" :	"financial___contractors__marketplace",
  "incomeStatement.contractors.wholesale" :	"financial___contractors__wholesale",
  "incomeStatement.contractors.retail" :	"financial___contractors__retail",
  "incomeStatement.peopleCosts" :	"",
  "incomeStatement.peopleCosts_dtcOnline" :	"",
  "incomeStatement.peopleCosts_marketplace" :	"",
  "incomeStatement.peopleCosts_wholesale" :	"",
  "incomeStatement.peopleCosts_retail" :	"",
  "incomeStatement.generalAndAdministrative" :	"financial___g_and_a",
  "incomeStatement.generalAndAdministrative_dtcOnline" :	"",
  "incomeStatement.generalAndAdministrative_marketplace" :	"",
  "incomeStatement.generalAndAdministrative_wholesale" :	"",
  "incomeStatement.generalAndAdministrative_retail" :	"",
  "incomeStatement.researchAndDevelopment" :	"financial___r_and_d",
  "incomeStatement.researchAndDevelopment_dtcOnline" :	"",
  "incomeStatement.researchAndDevelopment_marketplace" :	"",
  "incomeStatement.researchAndDevelopment_wholesale" :	"",
  "incomeStatement.researchAndDevelopment_retail" :	"",
  "incomeStatement.depreciation" :	"financial___depreciation",
  "incomeStatement.depreciation_dtcOnline" :	"",
  "incomeStatement.depreciation_marketplace" :	"",
  "incomeStatement.depreciation_wholesale" :	"",
  "incomeStatement.depreciation_retail" :	"",
  "incomeStatement.amortization" :	"financial___amortization",
  "incomeStatement.amortization_dtcOnline" :	"",
  "incomeStatement.amortization_marketplace" :	"",
  "incomeStatement.amortization_wholesale" :	"",
  "incomeStatement.amortization_retail" :	"",
  "incomeStatement.otherOperatingExpenses" :	"financial___other_operating_expenses",
  "incomeStatement.otherOperatingExpenses_dtcOnline" :	"",
  "incomeStatement.otherOperatingExpenses_marketplace" :	"",
  "incomeStatement.otherOperatingExpenses_wholesale" :	"",
  "incomeStatement.otherOperatingExpenses_retail" :	"",
  "incomeStatement.operatingExpenses" :	"",
  "incomeStatement.operatingExpenses_dtcOnline" :	"",
  "incomeStatement.operatingExpenses_marketplace" :	"",
  "incomeStatement.operatingExpenses_wholesale" :	"",
  "incomeStatement.operatingExpenses_retail" :	"",
  "incomeStatement.operatingIncome" :	"",
  "incomeStatement.operatingIncome_dtcOnline" :	"",
  "incomeStatement.operatingIncome_marketplace" :	"",
  "incomeStatement.operatingIncome_wholesale" :	"",
  "incomeStatement.operatingIncome_retail" :	"",
  "incomeStatement.otherExpenses" :	"financial___other_expenses",
  "incomeStatement.otherIncome" :	"financial___other_income",
  "incomeStatement.interestExpense" :	"financial___interest_expense",
  "incomeStatement.preTaxIncome" :	"",
  "incomeStatement.incomeTaxExpense" :	"financial___income_tax_expense",
  "incomeStatement.netIncome" :	"",
  "incomeStatement.EBIT" :	"",
  "incomeStatement.Depreciation" :	"",
  "incomeStatement.Amortization" :	"",
  "incomeStatement.EBITDA" :	"",
  "balanceSheet.currentAssets" :	"",
  "balanceSheet.cash" :	"financial___cash",
  "balanceSheet.accountsReceivable" :	"financial___accounts_receivable",
  "balanceSheet.inventory.finishedGoodsInTransit" :	"financial___inventory_finished_goods_in_transit",
  "balanceSheet.inventory.finishedGoodsInWarehouse" :	"financial___inventory_finished_goods_warehouse",
  "balanceSheet.inventory" :	"",
  "balanceSheet.workInProgress" :	"financial___inventory_wip",
  "balanceSheet.otherCurrentAssets" :	"financial___other_current_assets",
  "balanceSheet.totalCurrentAssets" :	"",
  "balanceSheet.fixedAssets" :	"financial___fixed_assets_ppe",
  "balanceSheet.accumulatedAmortization" :	"financial___accumulated_amortization",
  "balanceSheet.prepaidCapitalFees" :	"financial___prepaid_expense_capital_fees",
  "balanceSheet.otherAssets" :	"financial___other_assets",
  "balanceSheet.totalAssets" :	"",
  "balanceSheet.currentLiabilities" :	"",
  "balanceSheet.accountsPayable" :	"financial___accounts_payable",
  "balanceSheet.otherCurrentLiabilities" :	"financial___other_current_liabilities",
  "balanceSheet.lineOfCredit" :	"financial___line_of_credit",
  "balanceSheet.bainbridgecapitalcurrentliabilities" :	"financial___bainbridge_capital_current_liabilities",
  "balanceSheet.totalCurrentLiabilities" :	"",
  "balanceSheet.longTermLiabilities" :	"financial___long_term_liabilities",
  "balanceSheet.bainbridgecapitallongtermliabilities" :	"financial___bainbridge_capital_long_term_liabilities",
  "balanceSheet.totalLiabilities" :	"",
  "balanceSheet.commonStock" :	"financial___common_stock",
  "balanceSheet.beginningOfPeriodPaidInCapital" :	"",
  "balanceSheet.additionalPaidInCapitalOrDividend" :	"financial___additional_paid_in_capital_dividend",
  "balanceSheet.endOfPeriodPaidInCapital" :	"",
  "balanceSheet.retainedEarnings" :	"financial___retained_earnings",
  "balanceSheet.totalEquity" :	"",
  "cashFlowStatement.netIncome" :	"",
  "cashFlowStatement.depreciation" :	"",
  "cashFlowStatement.amortization" :	"",
  "cashFlowStatement.changeInWorkingCapital" :	"",
  "cashFlowStatement.accountsReceivable" :	"",
  "cashFlowStatement.inventory" :	"",
  "cashFlowStatement.prepaidExpense" :	"",
  "cashFlowStatement.accountsPayable" :	"",
  "cashFlowStatement.otherCurrentAssets" :	"",
  "cashFlowStatement.otherCurrentLiabilities" :	"",
  "cashFlowStatement.netCashProvidedByOperatingActivities" :	"",
  "cashFlowStatement.capitalExpenditures" :	"",
  "cashFlowStatement.otherAssets" :	"",
  "cashFlowStatement.netCashUsedInInvestingActivities" :	"",
  "cashFlowStatement.equityInvestment" :	"",
  "cashFlowStatement.lineOfCredit" :	"",
  "cashFlowStatement.longTermDebt" :	"",
  "cashFlowStatement.netCashProvidedByFinancingActivities" :	"",
  "cashFlowStatement.netCashFlow" :	"",
  "cashFlowStatement.beginningOfPeriodCash" :	"",
  "cashFlowStatement.endOfPeriodCash" :	"",
  "metrics.grossSalesGrowthPercent" :	"",
  "metrics.grossSalesGrowthPercentYearOverYear" :	"",
  "metrics.netRevenueMarginPercent" :	"",
  "metrics.costOfGoodsSoldPercent" :	"",
  "metrics.grossMarginPercent" :	"",
  "metrics.contributionMarginPercent" :	"",
  "metrics.adSpendGrowthPercent" :	"",
  "metrics.contributionMarginAfterMarketingPercent" :	"",
  "metrics.totalOpexPercent" :	"",
  "metrics.EBITDAMarginPercent" :	"",
  "metrics.operatingMarginPercent" :	"",
  "metrics.taxRatePercent" :	"",
  "metrics.netIncomeMarginPercent" :	"",
  "metrics.daysSalesOutstanding" :	"",
  "metrics.daysPayableOutstanding" :	"",
  "metrics.daysInventoryOutstanding" :	"",
  "metrics.cashConversionCycle" :	"",
  "metrics.orders" :	"",
  "metrics.orders.dtcOnline" :	"",
  "metrics.orders.marketplace" :	"",
  "metrics.orders.wholesale" :	"",
  "metrics.orders.retail" :	"",
  "metrics.orders.firstTimeOrders.dtcOnline" :	"",
  "metrics.orders.returningOrders.dtcOnline" :	"",
  "metrics.fulfillmentCostPerOrder.dtcOnline" :	"",
  "metrics.shippingCostPerOrder.dtcOnline" :	"",
  "metrics.merchantFeesPercent.dtcOnline" :	"",
  "metrics.salesFromReturningCustomers.dtcOnline" :	"",
  "metrics.salesFromNewCustomers.dtcOnline" :	"",
  "metrics.blendedPaidCAC.dtcOnline" :	"",
  "metrics.fullyLoadedCAC.dtcOnline" :	"",
  "metrics.newCustomerAverageOrderValue.dtcOnline" :	"",
  "metrics.returningCustomerAverageOrderValue.dtcOnline" :	"",
  "metrics.averageOrderValue.dtcOnline" :	"",
  "metrics.newCustomersAcquired.dtcOnline" :	"",
  "unused1" :	"financial___current_staff_salary_plus_benefits",
  "unused2" : 	"financial___current_staff_bonus_payout",
  "unused3" : 	"financial___new_staff_salary_plus_benefits"
};

const FINANCIAL_IDS = [
  "financial___gross_sales__dtconline",
  "financial___gross_sales__marketplace",
  "financial___gross_sales__wholesale",
  "financial___gross_sales__retail",
  "financial___discounts__dtconline",
  "financial___discounts__marketplace",
  "financial___discounts__wholesale",
  "financial___discounts__retail",
  "financial___returns__dtconline",
  "financial___returns__marketplace",
  "financial___returns__wholesale",
  "financial___returns__retail",
  "financial___shipping_income__dtconline",
  "financial___shipping_income__marketplace",
  "financial___shipping_income__wholesale",
  "financial___shipping_income__retail",
  "financial___taxes_collected__dtconline",
  "financial___taxes_collected__marketplace",
  "financial___taxes_collected__wholesale",
  "financial___taxes_collected__retail",
  "financial___product_cost__dtconline",
  "financial___product_cost__marketplace",
  "financial___product_cost__wholesale",
  "financial___product_cost__retail",
  "financial___import_freight_cost__dtconline",
  "financial___import_freight_cost__marketplace",
  "financial___import_freight_cost__wholesale",
  "financial___import_freight_cost__retail",
  "financial___import_duties_and_taxes__dtconline",
  "financial___import_duties_and_taxes__marketplace",
  "financial___import_duties_and_taxes__wholesale",
  "financial___import_duties_and_taxes__retail",
  "financial___fulfillment_costs__dtconline",
  "financial___fulfillment_costs__marketplace",
  "financial___fulfillment_costs__wholesale",
  "financial___fulfillment_costs__retail",
  "financial___other_variable_costs__dtconline",
  "financial___other_variable_costs__marketplace",
  "financial___other_variable_costs__wholesale",
  "financial___other_variable_costs__retail",
  "financial___shipping_expense__dtconline",
  "financial___shipping_expense__marketplace",
  "financial___shipping_expense__wholesale",
  "financial___shipping_expense__retail",
  "financial___merchant_fees__dtconline",
  "financial___merchant_fees__marketplace",
  "financial___merchant_fees__wholesale",
  "financial___merchant_fees__retail",
  "financial___direct_advertising__dtconline",
  "financial___direct_advertising__marketplace",
  "financial___direct_advertising__wholesale",
  "financial___direct_advertising__retail",
  "financial___other_advertising__dtconline",
  "financial___other_advertising__marketplace",
  "financial___other_advertising__wholesale",
  "financial___other_advertising__retail",
  "financial___other_marketing__dtconline",
  "financial___other_marketing__marketplace",
  "financial___other_marketing__wholesale",
  "financial___other_marketing__retail",
  "financial___payroll",
  "financial___current_staff_salary_plus_benefits",
  "financial___current_staff_bonus_payout",
  "financial___new_staff_salary_plus_benefits",
  "financial___marketing_agency__dtconline",
  "financial___marketing_agency__marketplace",
  "financial___marketing_agency__wholesale",
  "financial___marketing_agency__retail",
  "financial___legal_and_professional",
  "financial___contractors__dtconline",
  "financial___contractors__marketplace",
  "financial___contractors__wholesale",
  "financial___contractors__retail",
  "financial___g_and_a",
  "financial___r_and_d",
  "financial___depreciation",
  "financial___amortization",
  "financial___other_operating_expenses",
  "financial___other_expenses",
  "financial___other_income",
  "financial___interest_expense",
  "financial___income_tax_expense",
  "financial___cash",
  "financial___accounts_receivable",
  "financial___inventory_finished_goods_in_transit",
  "financial___inventory_finished_goods_warehouse",
  "financial___inventory_wip",
  "financial___other_current_assets",
  "financial___fixed_assets_ppe",
  "financial___accumulated_amortization",
  "financial___prepaid_expense_capital_fees",
  "financial___other_assets",
  "financial___accounts_payable",
  "financial___other_current_liabilities",
  "financial___line_of_credit",
  "financial___bainbridge_capital_current_liabilities",
  "financial___long_term_liabilities",
  "financial___bainbridge_capital_long_term_liabilities",
  "financial___common_stock",
  "financial___additional_paid_in_capital_dividend",
  "financial___retained_earnings"
];

export {KEY_PLAN_OPTIONS, FINANCIAL_IDS, M_MONTHLY_FINANCIAL_IDS_TO_FINANCIAL_IDS};
