/**
 * Copyright ©2024 Drivepoint
 */

import {EventBus, State, StateChangeEvent, Store} from "@bainbridge-growth/node-frontend";
import WebAppServerClient from "../services/WebAppServerClient.ts";
import NotFoundError from "../errors/NotFoundError.ts";

export default class FilesStore extends Store {

  private _timeout: any;

  init(): void {
    this.loading = true;
    EventBus.registerMany("plan:created", "plan:changed", "plan:removed", this._onEvent.bind(this));
    State.registerMany("company", (event: StateChangeEvent) => {
      this.value = [];
      this._throttledRefresh();
    });
  }

  async refresh(): Promise<void> {
    clearTimeout(this._timeout);
    return this.action(async () => {
      if (!this.company) { throw new NotFoundError(); }
      try {
        const res = await WebAppServerClient.request(`/api/company/${this.company?.id}/plan/list`, "GET");
        this.value = [...res ?? []];
      }
      catch (error) {
        this.value = {id: "unknown"};
        logger.error(error.message);
        EventBus.dispatch({type: "system:error", message: "An unexpected error occurred during loading plans information.", refresh: () => {this.refresh();}} as any);

      }
    });
  }

  private async _throttledRefresh(): Promise<void> {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.refresh();
    }, 1500);
  }

  get company(): any {
    return State.get("company");
  }

  private _onEvent(event: any): void {
    switch (event.type) {
      case "plan:created": return this._onPlanCreated(event.value);
      case "plan:changed": return this._onPlanChanged(event.value);
      case "plan:removed": return this._onPlanRemoved(event.value);
    }
  }

  private _onPlanCreated(plan: any): void {
    this.value = [...this.value.filter((it: any) => it.id !== plan.id), plan];
  }

  private _onPlanChanged(plan: any): void {
    this.value = this.value.map((it: any) => it.id === plan.id ? plan : it);
  }

  private _onPlanRemoved(plan: any): void {
    this.value = this.value.filter((it: any) => it.id !== plan.id);
  }

}
