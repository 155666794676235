/**
 * Copyright ©2024 Drivepoint
 */

export default class NotFoundError extends Error {

  code: number = 404;

  constructor(message?: any) {
    super(message ? message : "Not Found");
  }

}
