/**
 * Copyright ©2024 Drivepoint
 */

import {Utilities} from "@bainbridge-growth/node-common";
import {Mutex} from "async-mutex";
import {EventBus} from "@bainbridge-growth/node-frontend";
import ExcelService from "./ExcelService.ts";
import {ModelSettingsProps} from "../../types/ExcelModel.ts";

export default class ModelSettings {

  private static _mutex = new Mutex();
  private static _value: ModelSettingsProps = undefined;
  private static _timestamp: number = 0;

  static async start(): Promise<void> {
    EventBus.registerMany("WorksheetChanged", "WorksheetNameChanged", ModelSettings.onEvent);
  }

  static onEvent(event: any): void {
    if ((event.nameAfter === "Settings" || event.nameBefore === "Settings") && ModelSettings._timestamp) {
      ModelSettings.clear();
    }
  }

  static clear(): void {
    logger.trace("clearing cached settings");
    ModelSettings._timestamp = 0;
  }

  private static async _getPlanRelatedSettings(context: Excel.RequestContext) {
    try {
      const worksheet = context.workbook.worksheets.getItem("Plan Settings");
      const range = worksheet.getUsedRange(true);
      range.load("values");
      await context.sync();
      return range.values.reduce((newObj, el) => {
        newObj = {...newObj, [el[0].split(".")[1]]: el[2]};
        return newObj;
      }, {});
    }
    catch (err) {
      EventBus.dispatch({type: "system:error", message: "Something went wrong while getting item Id."} as any);
      return {};
    }
  }

  static async get(): Promise<ModelSettingsProps | undefined> {
    return ModelSettings._mutex.runExclusive(async () => {
      if (!Utilities.isEmpty(ModelSettings._value) && Date.now() - ModelSettings._timestamp < 60000) {
        logger.trace("using cached settings");
        return ModelSettings._value;
      }
      logger.trace("rebuilding settings...");
      ModelSettings._value = {};
      ModelSettings._timestamp = Date.now();
      const mode = await ExcelService.getCalculationMode();
      try {
        return await Excel.run(async (context: Excel.RequestContext) => {
          await ExcelService.setCalculationMode(Excel.CalculationMode.manual);
          const worksheet = context.workbook.worksheets.getItem("Settings");
          const range = worksheet.getUsedRange(true);
          range.load("values");
          const planSettings = await this._getPlanRelatedSettings(context);
          await context.sync();
          const settings = range.values
            // get setting value buried deep in the structure
            .reduce((values, value) => ([...values, {
              name: value[1],
              value: typeof value[3] === "string" ? value[3].trim() : value[3]
            }]), [])
            // exclude any non-"settings" key
            .filter(setting => setting.name?.startsWith("settings."))
            // remove "settings."
            .map(setting => ({name: setting.name = setting.name.replace("settings.", ""), value: setting.value}))
            .flat()
            // lowercase first letter of setting name
            .map(setting => ({name: setting.name[0].toLowerCase() + setting.name.slice(1), value: setting.value}))
            // rebuild as an object with names and values
            .reduce((settings: any, setting: any) => ({...settings, [setting.name]: setting.value}), {});
          ModelSettings._value = {...settings, ...planSettings};
          logger.trace("caching settings");
          return {...settings, ...planSettings};
        });
      } catch (error: any) {
        if (error.code === "ItemNotFound") {
          EventBus.dispatch({type: "system:error", message: "Could not find the Settings tab."} as any);
          return undefined;
        }
        throw error;
      } finally {
        await ExcelService.setCalculationMode(mode);
      }
    });
  }

}
